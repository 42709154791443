:root {
    --black: #333333;
    --red: #d83e46;
    --green: #979b69;
    --yellow: #eec859;
    --blue: #7fa7b3;
    --gmail: #D14836;
    --linkedin: #0077B5;
    --facebook: #4172B8;
    --twitter: #1DA1F2;
    --spotify: #1ED760;
    --pinterest: #BD081C;
    --blog: rgb(51, 51, 51);
}

* {
    transform: translateZ(0);
}

html,
body {
    min-height: 100%;
}

.container {
    height: 100%;
}

body {
    font-family: 'Open Sans', sans-serif;
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-variant-ligatures: common-ligatures;
    font-feature-settings: "liga", "dlig" 0;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-smoothing: antialiased;
}

body.loaded {
    overflow-x: hidden;
    overflow-y: auto;
}

/*
@supports (scroll-snap-align: start) {
    .container {
        -webkit-overflow-scrolling: touch;
        overflow-y: scroll;
        scroll-snap-type: y mandatory;
    }
}
 */

.dark {
    color: white;
    background: var(--black);
}

.light {
    color: var(--black);
    background: white;
}

.row {
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    align-items: center;
    scroll-snap-align: center;
    scroll-snap-stop: normal;
    overflow: hidden;
    position: relative;
    box-sizing: border-box;
}

@media screen and (max-width: 1024px) {
    .row {
        min-height: 100dvh;
    }
}

@media screen and (orientation: landscape) {
    .row {
        padding-top: 75px;
        padding-bottom: 75px;
    }
}

@media screen and (max-width: 767px) {
    .row {
        justify-content: center;
    }
}

.row .wrap {
    width: 100%;
    padding: 20px;
    box-sizing: border-box;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    flex-basis: 100%;
    flex-grow: 0;
    flex-shrink: 0;
}

.red {
    color: var(--red);
    text-decoration: none;
}

.green {
    color: var(--green);
    text-decoration: none;
}

.yellow {
    color: var(--yellow);
    text-decoration: none;
}

.blue {
    color: var(--blue);
    text-decoration: none;
}

svg {
    vertical-align: middle;
}

.scroll-down {
    position: absolute;
    left: 50%;
    transform: translateX(-20px);
    width: 40px;
    height: 40px;
    bottom: 35px;
    padding-left: 7px;
    cursor: pointer;
    z-index: 2;
    color: var(--black);
    fill: var(--black);
    box-sizing: border-box;
    opacity: 0;
    transition: opacity 500ms ease-out 1000ms;
}

.transition-complete.visible .scroll-down,
.visible:not(.top) .scroll-down {
    opacity: 1;
    transition: opacity 500ms ease-out 1000ms;
}

.scroll-down.svg svg {
    width: 25px;
    opacity: 1;
    transition: opacity .5s;
}

.scroll-down.svg .stroke {
    stroke: var(--black);
}

.scroll-down.svg .scroller {
    fill: var(--black);
    animation: updown 1s infinite;
}

.light .scroll-down {
    color: var(--black);
    fill: var(--black);
}

.light .scroll-down.svg .stroke {
    stroke: var(--black);
}

.light .scroll-down.svg .scroller {
    fill: var(--black);
}

.dark .scroll-down {
    color: white;
    fill: white;
}

.dark .scroll-down.svg .stroke {
    stroke: white;
}

.dark .scroll-down.svg .scroller {
    fill: white;
}

@keyframes updown {
    0% {
        transform: translate(0, 0);
    }
    50% {
        transform: translate(0, 5px);
    }
    100% {
        transform: translate(0, 0);
    }
}

.top {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3;
    background: white;
    scroll-snap-align: center;
    scroll-snap-stop: normal;
}

.top.transition-complete {
    position: static;
}

.top h1 {
    margin: 0;
    padding: 0;
    font-size: 10vw;
    line-height: 1;
    letter-spacing: -4vw;
    text-transform: lowercase;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    font-weight: 800;
    transform: scale(0);
    transition: transform 1000ms ease-in, letter-spacing 1000ms ease-in;
}

@media screen and (max-width: 767px) {
    .top h1 {
        font-size: 13vw;
        background-size: cover;
        flex-flow: column;
    }
}

.top.zoom-complete h1 {
    transform: scale(1);
    letter-spacing: -.35vw;
    transition: transform 1000ms ease-in, letter-spacing 1000ms ease-in;
}

.top h1 .color {
    background: transparent;
    transition: color 500ms ease-in, background 20000ms ease-out;
}

@media screen and (max-width: 767px) {
    .top h1 .color {
        flex-grow: 1;
        align-items: stretch;
        flex-basis: 100%;
        transform: scale(1.21);
    }
}

.top.transition-complete h1 .color {
    color: transparent;
    background: url(../img/color-pattern.jpg) center center repeat;
    background-size: contain;
    background-clip: text;
    //transition: background 10000ms ease-out;
}

.webp .top.transition-complete h1 .color {
    background: url(../img/color-pattern.webp) center center repeat;
    background-size: contain;
    background-clip: text;
}

.bio {
    text-align: center;
}

.bio h2 {
    margin: 0;
    padding: 0;
    font-weight: 400;
    line-height: 1.2;
    font-size: 2.5vw;
    letter-spacing: -.1vw;
    opacity: 0;
    transition: opacity 1000ms ease-in;
}

@media screen and (max-width: 767px) {
    .bio h2 {
        font-size: 5.8vw;
    }
}

.bio h2 span {
    font-weight: 600;
}

.bio.visible h2 {
    opacity: 1;
    transition: opacity 1000ms ease-in;
}

.skills picture {
    position: absolute;
    top: 50%;
    right: 100%;
    opacity: 0;
    transition: right 1000ms ease-out, opacity 1000ms ease-in;
    transform: translateY(-50%);
}

.skills.visible picture {
    right: 60%;
    opacity: 1;
    transition: right 1000ms ease-out, opacity 1000ms ease-in;
}

@media screen and (max-width: 767px) {
    .skills picture {
        position: static;
        transform: translateY(0);
        width: calc(100% - 20px);
        height: auto;
        flex-grow: 0;
        margin-top: 10px;
    }
}

.skills .wrap {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    flex-flow: column;
    position: relative;
    left: 20%;
    opacity: 0;
    transition: opacity 1500ms ease-in;
}

.skills.visible .wrap {
    opacity: 1;
    transition: opacity 1500ms ease-in;
}

@media screen and (max-width: 767px) {
    .skills,
    .skills .wrap {
        flex-flow: column;
        box-sizing: border-box;
        left: 0;
    }
}

.skills p {
    margin: 0 0 1.5vw 0;
    font-size: 1.5vw;
    line-height: 1.5;
}

.skills p a {
    text-decoration: none;
    color: #00679f;
}

@media screen and (max-width: 767px) {
    .skills p {
        flex-basis: 100%;
        margin: 0 0 20px 0;
        font-size: 20px;
        line-height: 1.5;
    }
}

.skills p:last-of-type {
    margin: 0;
}

@media screen and (max-width: 767px) {
    .skills .scroll-down {
        position: static;
        margin: 15px 0 35px 0;
        transform: translateX(0);
    }
}

.life picture {
    position: absolute;
    bottom: 0;
    left: 60%;
    z-index: 1;
    opacity: 0;
    transition: opacity 1000ms ease-in;
}

@media screen and (max-width: 767px) {
    .life picture {
        position: absolute;
        width: 100%;
        height: auto;
        flex-grow: 0;
        bottom: 0;
        left: 0;
    }
}

.life.visible picture {
    opacity: .2;
    transition: opacity 1000ms ease-in;
}

@media screen and (max-width: 767px) {
    .life.visible picture {
        opacity: .2;
    }
}

.life .wrap {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 50%;
    position: relative;
    z-index: 2;
    right: 20%;
    opacity: 0;
    transition: opacity 1500ms ease-in;
    flex-flow: column;
}

.life.visible .wrap {
    opacity: 1;
    transition: opacity 1500ms ease-in;
}

@media screen and (max-width: 767px) {
    .life,
    .life .wrap {
        flex-flow: column;
        box-sizing: border-box;
        left: 0;
    }
}

.life p {
    margin: 0 0 1.5vw 0;
    font-size: 1.5vw;
    line-height: 1.5;
}

@media screen and (max-width: 767px) {
    .life p {
        margin: 0 0 20px 0;
        font-size: 20px;
        line-height: 1.5;
    }
}

.life p:last-of-type {
    margin: 0;
}

@media screen and (max-width: 767px) {
    .life .scroll-down {
        position: static;
        margin: 15px 0 35px 0;
        transform: translateX(0);
    }
}

@media screen and (max-width: 767px) {
    picture,
    picture img {
        width: 100%;
        height: auto;
    }
}

.connect .wrap {
    flex-flow: column;
    align-items: center;
    flex-wrap: wrap;
}

.connect h3 {
    font-weight: 600;
    font-size: 3vw;
    margin: 0 0 30px 0;
    line-height: 1;
}

@media screen and (max-width: 767px) {
    .connect h3 {
        font-size: 30px;
    }
}

.connect p {
    margin: 0;
    font-size: 1.5vw;
    line-height: 1.5;
    text-align: center;
}

@media screen and (max-width: 767px) {
    .connect p {
        margin: 0;
        font-size: 18px;
        line-height: 1.5;
    }
}

.connect .icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
@media screen and (max-width: 767px) {
    .connect .icons {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(7, 1fr);
        margin-top: 30px;
    }
}

.connect .icons a {
    margin: 2.5vw 1.25vw 0;
}

@media screen and (max-width: 767px) {
    .connect .icons a {
        margin: 0 5px;
        width: auto;
    }
}

.connect .icons .svg {
    width: 3vw;
    height: 3vw;
}

@media screen and (max-width: 767px) {
    .connect .icons .svg {
        width: 100%;
        max-width: 35px;
        height: auto;
        margin: auto;
    }
}

.connect .gmail {
    fill: var(--gmail);
}

.connect .linkedin {
    fill: var(--linkedin);
}

.connect .facebook {
    fill: var(--facebook);
}

.connect .twitter {
    fill: var(--twitter);
}

.connect .spotify {
    fill: var(--spotify);
}

.connect .pinterest {
    fill: var(--pinterest);
}

.connect .blog {
    fill: var(--blog);
}

.bottom {
    width: 100%;
    text-align: center;
    font-weight: 300;
}

.bottom address {
    margin: 0 0 20px 0;
    line-height: 1;
    font-size: 12px;
}

.bottom .hide {
    display: none;
    visibility: hidden;
}